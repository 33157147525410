/**
 * 网络请求配置
 */
import { message } from 'antd'
import axios from 'axios'
import Cookies from 'js-cookie'
import NProgress from 'nprogress'
import { LoginReset } from './index'
import { sleep } from './time'
// import i18next from '_src/i18n'
// import { createNotification } from '_src/utils'
// import { login } from '_src/contracts/sign'

/**
 * @description: 语言
 */
// const LANGUAGETYPES = {
//   en_US: 2, // 英文
//   zh_CN: 3, // 繁体
//   es_ES: 4, //西班牙
// }

axios.defaults.timeout = 100000
axios.defaults.baseURL = process.env.REACT_APP_URL
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

// const transFromRequest = (values) => {
//   let form = new FormData()
//   for (let it in values) {
//     form.append(it, values[it])
//   }
//   return form
// }

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    const setToken = Cookies.get('setToken') || ''

    if (setToken) {
      if (config?.params?.isNoNeedToken) {
        // 不需要token
        // todo
      } else {
        config.headers['x-token'] = setToken // token
      }
    }

    NProgress.start()
    // 语言
    // const language = localStorage.getItem('i18nextLng') || ''
    // if (config.method === 'get') {
    //   if (language && config?.params) {
    //     config.params.language = LANGUAGETYPES[language] || ''
    //   }
    // }
    // if (config.method === 'post') {
    //   if (language && config?.data) {
    //     config.data.language = LANGUAGETYPES[language] || ''
    //   }
    // }

    // if (config.method === "post") {
    //   let form = new FormData();
    //   for (let key in config.data) {
    //     form.append(key, config.data[key]);
    //   }
    // }

    // if (token) {
    //   config.headers["X-Auth-Token"] = "vvvvvvvv"; // token
    // }
    // if (config.method === "post") {
    //   config.headers = {
    //     "Content-Type": "multipart/form-data",
    //   };
    // } else {
    //   config.headers = {
    //     "Content-Type": "application/json",
    //   };
    // }

    // config.headers = {
    //   "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",",
    //   "X-Auth-Token": localStorage.getItem("connect_address"),
    // };
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  async (response) => {
    NProgress.done()
    if (response && response.data.code == 1330) {
      // createNotification(i18next.t('common.waiting_confirm'), 'icon-status-warning', 1)
      // 需要签名
      message.error(response.data.msg)
      await sleep(600)
      Cookies.remove('setToken')
      window.open('/login', '_self')
      const res = await LoginReset(response.data.code)
      if (res) {
        console.log(res)
      }
      // const res = await login().catch((error) => {
      //   // 拒绝签名
      //   console.log(error)
      // })

      // if (res) {
      //   console.log(res)
      //   // if (response && response?.data) {
      //   //   Object.assign(response.data, { ...response.data, code: 1000 })
      //   // }
      // }
    } else if (response && response.data.code != 0) {
      msg(response)
    }

    return response
  },
  (error) => {
    NProgress.done()
    console.log('请求出错：', error)
    return error.response
  }
)

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
      })
      .then((response) => {
        // console.log("responseGet-------", response);
        response && landing(url, params, response.data)
        response ? resolve(response.data) : resolve(null)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        response && landing(url, data, response.data)
        response ? resolve(response.data) : resolve(null)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        msg(err)
        reject(err)
      }
    )
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data)
      },
      (err) => {
        msg(err)
        reject(err)
      }
    )
  })
}

//失败提示
function msg(err) {
  if (err && err.data) {
    switch (err.data.code) {
      case 400:
        message.error(err.data.msg)
        break
      case 401:
        message.error('未授权，请登录')
        break

      case 403:
        message.error(err.data.msg)
        break

      case 404:
        message.error('请求地址出错')
        break

      case 408:
        message.error('请求超时')
        break

      case 500:
        message.error(err.data.msg)
        break

      case 501:
        message.error('服务未实现')
        break

      case 502:
        message.error('网关错误')
        break

      case 503:
        message('服务不可用')
        break

      case 504:
        message('网关超时')
        break

      case 505:
        message('HTTP版本不受支持')
        break
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
  if (data.code === -1) {
    console.log(url, params, 'err:', data.err)
  }
}

// export function getQueryVariable(variable) {
//   let query = window.location.search.substring(1);
//   let vars = query.split("&");
//   for (let i = 0; i < vars.length; i++) {
//     let pair = vars[i].split("=");
//     if (pair[0] == variable) {
//       return pair[1];
//     }
//   }
//   return false;
// }
