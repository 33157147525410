import moment from 'moment'
import i18next from '_src/i18n'
// 睡眠几秒
export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
// ...时间之内
export function timeIn(s, e, date = new Date()) {
  return moment(date).isBetween(moment(s), moment(e))
}
// 换算 距离当前时间  ago
export function getDaysHoursMinutesSeconds(endDate, startDate, end = true) {
  let date3 = endDate.getTime() - new Date(startDate).getTime() //时间差的毫秒数
  let days = Math.floor(date3 / (24 * 3600 * 1000))
  let leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000))
  let leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000))

  let leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  let seconds = Math.round(leave3 / 1000)

  const dayStr = days > 0 ? `${days} ${i18next.t('common.days')}` : ''
  const hrsStr = hours > 0 ? `${hours} ${i18next.t('common.hrs')}` : ''
  const minsStr = minutes > 0 ? `${minutes} ${i18next.t('common.mins')}` : ''
  const secsStr = seconds > 0 ? `${seconds} ${i18next.t('common.secs')}` : ''

  // if (dayStr || hrsStr) {
  //   return `${dayStr} ${hrsStr} ${i18next.t(end ? '':'common.ago')}`
  // } else if (minsStr) {
  //   return `${minsStr} ${i18next.t(end ? '':'common.ago')}`
  // } else if (secsStr) {
  //   return `${secsStr} ${i18next.t(end ? '':'common.ago')}`
  // } else {
  //   return `0 ${i18next.t('common.secs')} ${i18next.t(end ? '':'common.ago')}`
  // }

  return `${dayStr} ${hrsStr} ${minsStr} ${secsStr}${i18next.t(end ? '' : 'common.ago')}`
}

/**
 * @description: 时间转换 ‘2023-01-24T17:37:44+08:00’ => 'YYYY-MM-DD hh:mm:ss'
 * @param {String} time
 * @return {String}
 */
export function formatTime(time, sort) {
  if (sort) {
    return moment(time).format('YYYY-MM-DD')
  } else {
    return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
}
