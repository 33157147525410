import React, { useEffect } from 'react'
import { useAppSelector } from '../../store/hooks'
import Icon from '../Icon'
import './index.less'
import { imgUrl } from '../../utils/staticUrl'
import { Tooltip } from 'antd'
const PassFooter = () => {
  const { lng } = useAppSelector((store) => store.global)
  // const list = [
  //   {
  //     name: '运营单位：',
  //     value: '国广链（北京）科技有限公司',
  //   },
  //   {
  //     name: '邮箱：',
  //     value: 'gfanx@gfanx.com',
  //   },
  //   {
  //     name: '服务热线：',
  //     value: '19919933995',
  //   },
  //   {
  //     name: '人工服务时间：',
  //     value: '工作日9:00-12:00，13:30-18:00',
  //   },
  // ]
  const share = [
    // {
    //   name: '小程序',
    //   img: imgUrl('aacCommon/enterprise_chat.jpg'),
    //   icon:'icon-qq-copy'
    // },
    {
      name: '微博',
      img: imgUrl('aacCommon/microblog_img.png'),
      icon: 'icon-weibo',
    },
    {
      name: '微信',
      img: imgUrl('aacCommon/wechat_img.png'),
      icon: 'icon-wechat',
    },
  ]
  const footerMenu = [
    {
      title: '关于',
      list: [
        {
          name: '关于数版中国',
          url: 'https://www.cccn.pro/home',
        },
        {
          name: '产品与服务',
          url: '',
        },
        {
          name: '联系我们',
          url: '',
        },
        {
          name: '品牌指南',
          url: '',
        },
      ],
    },
    {
      title: '帮助',
      list: [
        {
          name: '常见问题',
          url: '',
        },
        {
          name: '项目规范',
          url: '',
        },
        {
          name: '支持者指南',
          url: '',
        },
        {
          name: '项目发起指南',
          url: '',
        },
        {
          name: '众筹项目发起流程',
          url: '',
          // children: [
          //   {
          //     name: '众筹资质要求',
          //     url: '',
          //   },
          //   {
          //     name: '众筹项目安全交付指南',
          //     url: '',
          //   },
          //   {
          //     name: '数版中国账号认证',
          //     url: '',
          //   },
          //   {
          //     name: '数版中国社区公约',
          //     url: '',
          //   },
          //   {
          //     name: '关于《电子商务法》的重点提示',
          //     url: '',
          //   },
          //   {
          //     name: '抽奖工具使用说明',
          //     url: '',
          //   },
          //   {
          //     name: '知识产权侵权投诉指引',
          //     url: '',
          //   },
          //   {
          //     name: '众筹项目页面填写规范',
          //     url: '',
          //   },
          // ],
        },
      ],
    },
    {
      title: '条款',
      list: [
        {
          name: '用户注册协议',
          url: '',
        },
        {
          name: '发起者协议',
          url: '',
        },
        {
          name: '支持者协议',
          url: '',
        },
        {
          name: '其他协议',
          url: '',
          // children: [
          //   {
          //     name: '结算及退矿流程',
          //     url: '',
          //   },
          //   {
          //     name: '用户注册协议',
          //     url: '',
          //   },
          //   {
          //     name: '数版中国定向营销服务协议',
          //     url: '',
          //   },
          //   {
          //     name: '抽奖活动管理规范',
          //     url: '',
          //   },
          //   {
          //     name: '个人信息共享清单',
          //     url: '',
          //   },
          //   {
          //     name: '应用权限申请与使用情况说明',
          //     url: '',
          //   },
          //   {
          //     name: '发行方协议-数字藏品类',
          //     url: '',
          //   },
          //   {
          //     name: '数版中国用户协议-数字藏品类',
          //     url: '',
          //   },
          //   {
          //     name: '用户服务协议-抽卡机',
          //     url: '',
          //   },
          //   {
          //     name: '退款流程',
          //     url: '',
          //   },
          //   {
          //     name: '承诺函',
          //     url: '',
          //   },
          // ],
        },
      ],
    },
    {
      title: '电商',
      list: [
        {
          name: '商城协议中心',
          url: '',
        },
        {
          name: '类目资质清单',
          url: '',
        },
        {
          name: '文创商城用户服务协议',
          url: '',
        },
        {
          name: '文创商城预售协议',
          url: '',
        },
      ],
    },
  ]
  useEffect(() => {}, [lng])
  return (
    <div id="pass_footer">
      <div className="content">
        <div className="footer_content">
          <div className="content">
            {footerMenu &&
              footerMenu.map((item, index) => (
                <div className="footer_menu" key={index}>
                  <div className="menu_title">{item.title}</div>
                  {item.list &&
                    item.list.map((it, id) => (
                      <div className="menu_list" key={id}>
                        <div className="list">
                          {it.url ? (
                            <a href={it.url} target={'blank'}>
                              <div className="menu_name">{it.name}</div>
                            </a>
                          ) : (
                            <div className="menu_name">{it.name}</div>
                          )}
                          {it.children && <Icon type="icon-down" />}
                        </div>
                        <div className="list_children">
                          {it.children &&
                            it.children.map((i, d) => (
                              <div className="child" key={d}>
                                {i.url ? (
                                  <a href={i.url} target={'blank'}>
                                    <div className="children">{i.name}</div>
                                  </a>
                                ) : (
                                  <div className="children">{i.name}</div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
          <div className="share">
            {share &&
              share.map((item, index) => (
                <Tooltip
                  placement="top"
                  overlayClassName="share_tooltip"
                  title={
                    <>
                      <img src={item.img} />
                    </>
                  }
                  key={index}
                >
                  <div className="share_box" key={index}>
                    <Icon className="share_icon" type={item.icon} />
                    <p>{item.name}</p>
                  </div>
                </Tooltip>
              ))}
          </div>
        </div>
        <div className="footer_records">
          <div className="note">
            {
              'Copyright 2016-2017 Beijing Culture Equity Exchange CO., LTD, All Rights Reserved； 国广链（北京）科技有限公司 '
            }
          </div>
          <div className="record">
            {' 京ICP备2022008016号-3；增值电信许可证 京B2-20222125； 艺术品经营单位备案证明 京门艺(2023) 0017号'}
          </div>
          {/* <div className="copyright">
            {'Copyright 2016-2017 Beijing Culture Equity Exchange CO., LTD, All Rights Reserved'}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PassFooter
