import React from 'react'
import ReactLoading from 'react-loading'
import classnames from 'classnames'

import { Tooltip } from 'antd'

import './index.less'

const ButtonAnt = ({
  loading = false,
  zIndex = 98,
  height = 36, //按钮高度可配
  padding = '0 16px',
  width,
  showTooltip,
  tooltipText = '',
  onClick,
  disabled = false,
  monochrome = true, //色调 默认单色
  className,
  ...props
}) => {
  function handleClick() {
    if (disabled || loading) return
    onClick && onClick()
  }

  return (
    <div id="inft-button-area" className={classnames('cust-button', className)}>
      <Tooltip
        zIndex={zIndex}
        open={showTooltip}
        trigger={'hover'}
        color={'#000'}
        placement="top"
        title={tooltipText}
        getPopupContainer={() => document.getElementById('inft-button-area')}
      >
        <div
          onClick={handleClick}
          {...props}
          className={classnames('button-container', disabled || loading ? 'button-disabled-primary' : '')}
        >
          <div
            className={classnames('button-text', { 'button-text-drak': monochrome })}
            style={{
              height: height + 'px',
              width: width + 'px',
              lineHeight: height + 'px',
              padding: padding ? padding : '0 16px',
            }}
          >
            {loading && (
              <ReactLoading className="cust-button-loading" type="spokes" color="#000" height={16} width={16} />
            )}

            <div className="text-title">{props.children}</div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default ButtonAnt
