import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
// import Media from 'react-media'
import './index.less'
import LanguageSelect from '../LanguageSelect'

import PassMenu from '../Menu'
import ButtonAnt from '../Button'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setIsLogin } from '../../store/global/reducer'
import { Dropdown, message, Space, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import { imgUrl } from '_src/utils/staticUrl'
import { getUserInfo } from '../../servers/V1/personalCenter'
import { setUserInfo } from '_src/store/global/reducer'
const PassHeader = ({ className }) => {
  const { isLogin, userInfo } = useAppSelector((store) => store.global)
  const token = Cookies.get('setToken' || '')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const handleHome = () => {
    history.push({
      pathname: '/home',
    })
  }
  const items = [
    {
      label: '用户中心',
      key: '1',
    },
    {
      label: '退出登录',
      key: '2',
    },
  ]
  const rqcode = [
    {
      name: 'APP下载',
      img: imgUrl('aacCommon/appcode.jpg'),
    },
    {
      name: 'H5地址',
      img: imgUrl('aacCommon/h5code.jpg'),
    },
  ]
  const onClick = (data) => {
    if (data.key == 1) {
      history.push({
        pathname: '/personalCenter',
      })
    } else if (data.key == 2) {
      dispatch(setIsLogin(false))
      Cookies.remove('setToken')
      dispatch(setUserInfo({}))
      history.push('/login')
      message.success('退出成功')
    }
  }
  const getUser = async () => {
    try {
      const res = await getUserInfo()
      if (res && res.code == 0) {
        dispatch(setUserInfo(res.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (!token || token == '') {
      dispatch(setIsLogin(false))
    }
  }, [token])
  useEffect(() => {
    if (isLogin) {
      getUser()
    }
  }, [isLogin])
  return (
    <div id={classNames('pass_header', className)}>
      {/* <Media
        queries={{
          small: '(max-width: 750px)',
          medium: '(min-width: 751px) and (max-width: 1109px)',
          large: '(min-width: 1110px)',
        }}
      >
        {(matches) => (
          <>
            {(matches.small || matches.medium) && (
              <>
                <div className="header_left">
                  <div className="logo" onClick={handleHome}></div>
                </div>
                <div className="header_right">
                  <PassMenu />
                  <LanguageSelect />
                </div>
              </>
            )}
            {matches.large && (
              <> */}
      <div className="header_left">
        <div className="logo" onClick={handleHome}></div>
        <div className="logo_title">{t('header.login_title')}</div>
      </div>
      <PassMenu />
      <div className="header_right">
        {!!0 && <LanguageSelect />}
        <div className="code">
          {rqcode &&
            rqcode.map((item, index) => (
              <Tooltip
                overlayClassName="code_tooltip"
                title={<img className="code_img" src={item.img} />}
                key={index}
                placement={'bottom'}
                color={'#fff'}
              >
                <div className="code_list" key={index}>
                  {item.name}
                </div>
              </Tooltip>
            ))}
        </div>
        {isLogin ? (
          <>
            <Dropdown
              overlayClassName="user_down"
              menu={{
                items,
                onClick,
              }}
            >
              <Space>
                <div className="user_center">
                  <div className="header_avatar">
                    <img src={userInfo?.avatar || imgUrl('aacCommon/avatar.jpg')} />
                  </div>
                  <DownOutlined className="user_center_down" />
                </div>
              </Space>
            </Dropdown>
          </>
        ) : (
          <>
            <ButtonAnt
              className={'register'}
              onClick={() => {
                history.push('/login/register')
              }}
              monochrome={false}
            >
              {t('common.register')}
            </ButtonAnt>
            <ButtonAnt
              className={'login_button'}
              onClick={() => {
                history.push('/login')
                dispatch(setIsLogin(isLogin))
              }}
            >
              {t('common.login')}
            </ButtonAnt>
          </>
        )}
      </div>
    </div>
  )
}

export default PassHeader
